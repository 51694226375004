<template>
	<v-container>
		<div class="text-h3" :style="{'font-weight':400, 'letter-spacing': '0.4vw !important;', 'margin':'0px 0 30px 0'}"><v-icon :style="{'font-size':'50px', 'margin':'-8px 10px 0 10px'}">mdi-book-open-variant</v-icon>Oppskrifter</div>
		<div class="grey--text mb-2" v-if="$store.getters.searchText.length == 0">Ingen matvarer valgt...</div>
		<div class="grey--text mb-2" v-else-if="$store.getters.getRecipes.length == 0">Fant ingen oppskrifter... Vennligs prøv andre ingredienser. Er det noe du ser etter så kan du også foreslå dette til oss på kontaktskjemaet hos https://bitkraken.no </div>
		<v-row dense
		justify="start">
			<v-col
			v-for="(recipe) in $store.getters.getRecipes"
			v-bind:key="recipe.title"
			lg="4"
			md="6"
			sm="12"
			>
			<v-card>
				<router-link :to="{ name: 'show-recipe', params: { recipe: recipe.title } }">
				<v-img
				height="250"
				:src="'https://assets.brukmat.no/images/'+recipe.img_url"
				class="white--text align-end"
				gradient="rgba(0,0,0,0), 75%, rgba(0,0,0,1)">
					<v-card-title class="text-capitalize">
						{{recipe.title}}
					</v-card-title>
				</v-img>
				</router-link>
				<v-card-title :class="{ 'gotAll' : gotAllIngredients($store.getters.searchText, recipe.ingredients)}">Inneholder {{calculateIngredientsEquality($store.getters.searchText, recipe.ingredients)}} av {{recipe.ingredients.length}} matvarer</v-card-title>
				<v-divider></v-divider>
				<!--
				<v-list>
					<v-list-item v-for="(calIng) in calculateIngredients($store.getters.searchText, recipe.ingredients)" v-bind:key="recipe.title+calIng.ingredient.title">
						<div :class="{ 'isIncluded' : calIng.isIncluded}">
						<v-list-item-title>{{ calIng.ingredient.title }}</v-list-item-title>
						<v-list-item-subtitle class="text-right">{{ calIng.ingredient.amount }} {{ calIng.ingredient.measurement }}</v-list-item-subtitle>
						</div>
					</v-list-item>
				</v-list>
				-->
				<v-card-actions>
					<v-btn
						text
						color="teal accent-4"
						:to="{ name: 'show-recipe', params: { recipe: recipe.title } }"
					>
						Se detaljer
					</v-btn>
				</v-card-actions>
			</v-card>
			</v-col>
		</v-row>
	</v-container>
	
</template>
<style>
.v-card--reveal {
	bottom: 0;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
}
</style>
<script>
	export default {
        metaInfo: {
            // override the parent template and just use the above title only
            title: 'Oppskrifter',
            // all titles will be injected into this template
            titleTemplate: '%s | Brukmat'
        },
		data: () => ({
			reveal: false,
		}),
		methods: {
			calculateIngredientsEquality: function(array1, array2){
				console.log(array1, " arr2:",array2);

				var equals = array2.map(x => array1.some(i => {
					console.log("I is", i);
					return i.product == x.title;
				}));
				var amountOfIngredients = equals.filter(Boolean).length;
				//console.log(equals);
				return amountOfIngredients;
			},
			calculateIngredients: function(array1, array2){
				var equals = array2.map(x => {
					let isIncluded = array1.some(i => {
					console.log("I is", i);
					return i.product == x.title;
					});
					return {
						ingredient:x,
						isIncluded:isIncluded
					}
				});
				return equals;
			},
			gotAllIngredients: function(array1, array2){
				var equals = array2.map(x => array1.some(i => {
					console.log("I is", i);
					return i.product == x.title;
				}));
				return equals.every(v => v === true);
			}

		}
	}
</script>
<style type="text/css">
	.isIncluded {
		color:green;
		font-weight: 800;
	}
	.gotAll {
		color:green;
		font-weight: 800;
	}
</style>